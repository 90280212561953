import "./App.css";
import axios from "axios";
import Landingpage from "./components/Landingpage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Selfemp from "./components/Selfemp";
import Skilling from "./components/Skilling";
import Navbar from "./components/Navbar";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HorizontalForm from "./components/index";

function App() {
  const [params, setParams] = useSearchParams();
  const [bannerState, setBannerState] = useState("");
  const id = params.get("id");
  const showAdmins = params.get("cpid")
    ? params.get("cpid").split("-").includes("admins")
    : false;
  // console.log("Admin banner", showAdmins);

  const fetchBannerbyId = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/channelpartner/getbanner`,
        { params: { cpid: params.get("Id") } }
      )
      .then((res) => {
        console.log("banner get api", res.data);
        // setIndustryOptions(res.data);
        // sessionStorage.setItem("banner", res.data.banner_data.jsbanner);
        setBannerState(res.data.banner_data.jsbanner);
      })
      .catch((err) => {
        console.log(err);
        // sessionStorage.setItem("banner", "");
      });
  };
  useEffect(() => {
    fetchBannerbyId();
  }, [params.get("Id")]);

  useEffect(() => {
    // sessionStorage.setItem("id", id ? id : "");
    sessionStorage.setItem(
      "source",
      params.get("source") ? params.get("source") : ""
    );
    // sessionStorage.setItem(
    //   "cpid",
    //   params.get("cpid")
    //     ? params.get("cpid")
    //     : params.get("Id")
    //     ? params.get("Id")
    //     : ""
    // );
    sessionStorage.setItem(
      "id",
      id ? id : params.get("Id") ? params.get("Id") : ""
    );
    sessionStorage.setItem(
      "cpid",
      params.get("cpid") ? params.get("cpid") : ""
    );
    sessionStorage.setItem("adminsBanner", showAdmins);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={<Landingpage bannerState={bannerState} />}
        />
        <Route
          path="/self-employment"
          element={<Selfemp bannerState={bannerState} />}
        />
        <Route
          path="/skilling"
          element={<Skilling bannerState={bannerState} />}
        />
        <Route
          path="/jobs"
          element={<HorizontalForm bannerState={bannerState} />}
        />
      </Routes>
    </div>
  );
}

export default App;
