import React from 'react'
import { AppBar, Toolbar, Typography, Button, Box, useMediaQuery } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import img1 from '../assets/images/mysba.png'
import img2 from '../assets/images/sakshamnew.png'
import styled from '@emotion/styled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSearchParams } from 'react-router-dom';


const STypography1 = styled(Typography)({
    fontFamily: 'Sora',
    color: '#FFFFFF',
    textAlign: 'left',
    marginLeft: '12px'
})

function Navbar() {
    const [params, setParams] = useSearchParams()
    const id = params.get("id")
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <AppBar position="static" elevation={1} sx={{ background: '#FFFFFF' }}>
            <Box
                sx={{
                    background: '#FFFFFF',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mr: 0
                }}
            >
                <Box
                    sx={{
                        pl: 2,
                        pr: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img src={id === "srs" ? img2 : img1} style={matches ? { height: '55px' } : { height: '40px' }} />
                </Box>

                <Box sx={matches ?
                    id === "srs" ? {
                        background: '#151B54',
                        maxWidth: '700px',
                        height: '100%',
                        p: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        mr: 0,
                        ml: 2,
                        borderTopLeftRadius: '20px'
                    } :
                        {
                            background: '#1E8449',
                            maxWidth: '700px',
                            height: '100%',
                            p: 1,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mr: 0,
                            ml: 2,
                            borderTopLeftRadius: '20px'
                        } :
                    id === "srs" ? {
                        background: '#151B54',
                        maxWidth: '700px',
                        height: '100%',
                        p: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        mr: 0,
                        ml: 1,
                        borderTopLeftRadius: '20px',
                        flexDirection: 'column'
                    } :
                        {
                            background: '#1E8449',
                            maxWidth: '700px',
                            height: '100%',
                            p: 0,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mr: 0,
                            ml: 1,
                            borderTopLeftRadius: '20px',
                            flexDirection: 'column'
                        }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', m: 1 }}>
                        {id === "srs" ?
                            <WhatsAppIcon /> :
                            <CallIcon />
                        }

                        <STypography1 variant="body1">
                            {matches ?
                                <>
                                    <span style={{ fontSize: '12px' }}>
                                        {id === "srs" ? 'Send "Hi" on' : "Helpline"}
                                    </span>
                                    <br />
                                </> :
                                null
                            }
                            {id === "srs" ? "+91 8655826684" : "+91 7400052852"}
                        </STypography1>
                    </Box>


                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', m: 1 }}>
                        <EmailIcon />

                        <STypography1 variant="body1">
                            {matches ?
                                <>
                                    <span style={{ fontSize: '12px' }}>Email</span>
                                    <br />
                                </> :
                                null
                            }
                            {id === 'srs' ? "info@sakshamtifac.org" : "info@mysba.co.in"}
                        </STypography1>
                    </Box>
                </Box>
            </Box>
        </AppBar>
    )
}

export default Navbar
