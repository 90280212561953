import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import {
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HandshakeIcon from "@mui/icons-material/Handshake";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import "../assets/styles/styles.css";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import cover2 from "../assets/images/form 7.jpg";
import cover3 from "../assets/images/SRS.jpg";
import saksham from "../assets/images/saksham.jpg";
import adminsBanner from "../assets/images/admin-banner1.jpeg";
import vkmsBanner from "../assets/images/24.png";
import hindurojgarBanner from "../assets/images/hindurojgar-banner.jpg";
import axios from "axios";
import PropTypes from "prop-types";

const STypography1 = styled(Typography)({
  fontFamily: "Sora",
  fontWeight: 600,
  color: "#FFFFFF",
  textAlign: "left",
  marginLeft: "12px",
});

const SBox1 = styled(Box)({
  margin: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SBox2 = styled(Box)({
  padding: "20px",
  color: "#FFFFFF",
  textAlign: "left",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
});

const Landingpage = (props) => {
  const { bannerState } = props;
  const [params, setParams] = useSearchParams();
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const id = params.get("id")
    ? params.get("id")
    : params.get("Id")
    ? params.get("Id")
    : "";
  const source = params.get("source");
  const cpid = params.get("cpid")
    ? params.get("cpid")
    : // : params.get("Id")
      // ? params.get("Id")
      "";
  const orgid = params.get("orgid");
  const jobid = params.get("jobid");
  const showAdmins = cpid ? cpid.split("-").includes("admins") : false;
  console.log("Admin banner 1", showAdmins);
  console.log(params.get("Id"));

  useEffect(() => {
    sessionStorage.setItem(
      "cpid",
      params.get("cpid")
        ? params.get("cpid")
        : // : params.get("Id")
          // ? params.get("Id")
          ""
    );
    // sessionStorage.setItem("id", id ? id : "");
    sessionStorage.setItem(
      "id",
      id ? id : params.get("Id") ? params.get("Id") : ""
    );
    sessionStorage.setItem(
      "source",
      params.get("source") ? params.get("source") : ""
    );
    sessionStorage.setItem("adminsBanner", showAdmins);
  }, []);

  return (
    <div>
      {/*<Navbar />*/}
      {/*
            {id === "" || id === null ?
                <Toolbar /> :
                null
            }
            */}

      <div style={{ textAlign: "center" }}>
        {showAdmins ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={adminsBanner}
              className="logo-img1"
            />
          </div>
        ) : bannerState !== "" ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={bannerState}
              className="logo-img1"
            />
          </div>
        ) : id === "mysba" ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={cover2}
              className="logo-img1"
            />
          </div>
        ) : id === "srs" || id === null || id === "" ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={cover3}
              className="logo-img1"
            />
          </div>
        ) : id === "admins" ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={adminsBanner}
              className="logo-img1"
            />
          </div>
        ) : id === "Vakrangee" ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={vkmsBanner}
              className="logo-img1"
            />
          </div>
        ) : id === "hindurojgaar" ? (
          <div>
            <img
              style={{ width: "100%", height: "auto" }}
              src={hindurojgarBanner}
              className="logo-img1"
            />
          </div>
        ) : null}

        <div style={{ width: "100%" }}>
          <Box
            sx={
              matches
                ? id === "mysba" ||
                  id === "srs" ||
                  id === "admins" ||
                  id === "" ||
                  id === "Vakrangee" ||
                  id === "hindurojgaar" ||
                  id === null
                  ? {
                      minHeight: "250px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "1200px",
                      ml: "auto",
                      mr: "auto",
                    }
                  : {
                      minHeight: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "1200px",
                      ml: "auto",
                      mr: "auto",
                    }
                : {
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "1200px",
                    mb: 6,
                  }
            }
          >
            <Grid
              container
              spacing={5}
              sx={{ width: "100%", ml: "auto", mr: "auto" }}
            >
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <SBox1
                  onClick={() =>
                    navigate(
                      `/jobs?cpid=${cpid}&id=${id}&source=${source}&jobid=${jobid}&orgid=${orgid}`
                    )
                  }
                >
                  <div className="example-container">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.5 }}
                    >
                      <Box sx={{ textAlign: "right" }}>
                        <SBox2>
                          <STypography1 variant="h4">
                            Jobs & Opportunities
                          </STypography1>

                          <WorkOutlineIcon
                            fontSize="large"
                            sx={
                              id === "srs"
                                ? { color: "#EBF5FB" }
                                : { color: "#FFFFC2" }
                            }
                          />
                        </SBox2>

                        {matches ? (
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/jobs?cpid=${cpid}&id=${id}&source=${source}&jobid=${jobid}&orgid=${orgid}`
                              )
                            }
                            sx={
                              id === "srs"
                                ? {
                                    background: "#EBF5FB",
                                    color: "#145A32",
                                    mt: -8,
                                    p: 2,
                                    boxShadow:
                                      " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    "&:hover": {
                                      background: "#EBF5FB",
                                    },
                                  }
                                : {
                                    background: "#FFFFC2",
                                    color: "#145A32",
                                    mt: -8,
                                    p: 2,
                                    boxShadow:
                                      " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    "&:hover": {
                                      background: "#FFFFC2",
                                    },
                                  }
                            }
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </motion.div>
                  </div>
                </SBox1>
              </Grid>

              <Grid item lg={4} md={6} sm={12} xs={12}>
                <SBox1
                  onClick={() =>
                    navigate(
                      `/self-employment?cpid=${cpid}&id=${id}&source=${source}&jobid=${jobid}&orgid=${orgid}`
                    )
                  }
                >
                  <div className="example-container">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.5 }}
                    >
                      <Box sx={{ textAlign: "right" }}>
                        <SBox2>
                          <STypography1 variant="h4">
                            Self- Employment
                          </STypography1>

                          <HandshakeIcon
                            fontSize="large"
                            sx={
                              id === "srs"
                                ? { color: "#EBF5FB" }
                                : { color: "#FFFFC2" }
                            }
                          />
                        </SBox2>

                        {matches ? (
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/self-employment?cpid=${cpid}&id=${id}&source=${source}&jobid=${jobid}&orgid=${orgid}`
                              )
                            }
                            sx={
                              id === "srs"
                                ? {
                                    background: "#EBF5FB",
                                    color: "#145A32",
                                    mt: -8,
                                    p: 2,
                                    boxShadow:
                                      " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    "&:hover": {
                                      background: "#EBF5FB",
                                    },
                                  }
                                : {
                                    background: "#FFFFC2",
                                    color: "#145A32",
                                    mt: -8,
                                    p: 2,
                                    boxShadow:
                                      " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    "&:hover": {
                                      background: "#FFFFC2",
                                    },
                                  }
                            }
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </motion.div>
                  </div>
                </SBox1>
              </Grid>

              <Grid item lg={4} md={6} sm={12} xs={12}>
                <SBox1
                  onClick={() =>
                    navigate(
                      `/skilling?cpid=${cpid}&id=${id}&source=${source}&jobid=${jobid}&orgid=${orgid}`
                    )
                  }
                >
                  <div className="example-container">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.5 }}
                    >
                      <Box sx={{ textAlign: "right" }}>
                        <SBox2>
                          <STypography1 variant="h4">Skilling</STypography1>

                          <WorkspacePremiumIcon
                            fontSize="large"
                            sx={
                              id === "srs"
                                ? { color: "#EBF5FB" }
                                : { color: "#FFFFC2" }
                            }
                          />
                        </SBox2>

                        {matches ? (
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/skilling?cpid=${cpid}&id=${id}&source=${source}&jobid=${jobid}&orgid=${orgid}`
                              )
                            }
                            sx={
                              id === "srs"
                                ? {
                                    background: "#EBF5FB",
                                    color: "#145A32",
                                    mt: -8,
                                    p: 2,
                                    boxShadow:
                                      " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    "&:hover": {
                                      background: "#EBF5FB",
                                    },
                                  }
                                : {
                                    background: "#FFFFC2",
                                    color: "#145A32",
                                    mt: -8,
                                    p: 2,
                                    boxShadow:
                                      " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    "&:hover": {
                                      background: "#FFFFC2",
                                    },
                                  }
                            }
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </motion.div>
                  </div>
                </SBox1>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
Landingpage.propTypes = {
  bannerState: PropTypes.string,
};
export default Landingpage;
