/**
 * Form Elemets
 */
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  job_location,
  talukas,
  socialData,
  fav,
  salary_range,
} from "./constantskill";
import watsapp from "../assets/images/watsapp.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "@emotion/styled";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Stepper,
  StepLabel,
  Step,
  Button,
  useMediaQuery,
  LinearProgress,
} from "@mui/material";
import {
  SBox1,
  SBox2,
  SBox3,
  SBox4,
  SBox5,
  SBox7,
  SBoxN1,
  SBoxN2,
  SButton1,
  SButton2,
  SButton3,
  STypography1,
  STypography10,
  STypography11,
  STypography2,
  STypography3,
  STypography4,
  STypography5,
  STypography6,
  STypography7,
  STypography8,
  STypography9,
} from "../assets/styles/Styles.js";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import img1 from "../assets/images/imgOne.png";
import img2 from "../assets/images/imgTwo.png";
import img4 from "../assets/images/imgFour.png";
import img5 from "../assets/images/imgFive.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import saksham from "../assets/images/SRS-3.jpg";
import mysba from "../assets/images/form 9.png";
import adminsBanner from "../assets/images/admin-banner1.jpeg";
import vkmsBanner from "../assets/images/24.png";
import hindurojgarBanner from "../assets/images/hindurojgar-banner.jpg";
import cover1 from "../assets/images/SBA3.svg";
import cover2 from "../assets/images/banner.jpg";
import {
  skills,
  sector,
  gender,
  taluka,
  specialization,
  grad_data,
  category,
  dist,
  maritalStatus,
  partTime,
  priorities,
  rank,
  serviceType,
  experience,
  //languageList,
  opportunities,
} from "./constantskill";
import axios from "axios";
import { checkPropTypes } from "prop-types";
import Swal from "sweetalert2";
import { Table } from "antd";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";

const newopportunities = [
  {
    label: "Electronics and Mobile Repair",
    value: "Electronics and Mobile Repair",
  },
  {
    label: "Employability and Soft Skills",
    value: "Employability and Soft Skills",
  },
  {
    label: "DRA Certification",
    value: "DRA Certification",
  },
];

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            backgroundColor: "#e8efec",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#226447",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="#226447">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Skilling = (props) => {
  const { bannerState } = props;
  const ref = useRef();
  const navigate = useNavigate();
  const [hide, setHide] = useState(true);
  const [show, setShow] = useState(-1);
  const [candidateId, setCandidateId] = useState("");
  const [open4, setOpen4] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [obj, setObj] = useState({
    org_address: "",
    org_state: "",
    org_district: "",
    taluka: "",
    org_pincode: "",
    mobnum: "",
    email: "",
    firstnm: "",
    lastnm: "",
    marital_status: "",
    gender: "",
    birth_date: "",
  });
  const [statenew, setStateNew] = useState([]);
  const [districtnew, setDistrictNew] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [submitform, setSubmitform] = useState(false);
  const [submitformOne, setSubmitformOne] = useState(false);
  const [selectCourse, setSelectCourse] = useState([]);
  const [mobilenum, setMobilenum] = useState("");
  const [nextStep, setNextStep] = useState(false);
  const [regId, setRegId] = useState("");
  const [preffere, setPreffere] = useState([]);
  const [resumefile, setResumeFile] = useState("");
  const date = new Date();
  const newdate = `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()}`;

  const [searchParams, setSearchParams] = useSearchParams();
  const [centerId, setCenterId] = useState("");
  const [centerHeadId, setCenterHeadId] = useState("");
  const [centerEmployeeId, setCenterEmployeeId] = useState("");
  const [languagesall, setLanguagesall] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [skillingall, setSkillingall] = useState([]);
  const [resumeshow, setResumeshow] = useState(false);
  const [resumeshow1, setResumeshow1] = useState(0);
  const matchmd = useMediaQuery("max-width:700px");
  const matchsm = useMediaQuery("(min-width:600px)");
  const matched = useMediaQuery("(min-width:600px)");
  const [formDetails, setFormDetails] = useState({
    firstname: "",
    lastname: "",
    //fathername: "",
    //mothername: "",
    religion: "",
    mother_tongue: "",
    email: "",
    date_of_birth: "",
    gender: "",
    height: "",
    address: "",
    pincode: "",
    state: "",
    desired_salary: "",
    current_salary: "",
    priority: "",
    district: "",
    taluka: "",
    highest_education: "",
    degree: "",
    stream: "",
    mobile: "",
    cast: "",
    des_industry: "",
    job_Location: "",
    job_role: "",
    // sub_cast: "",
    know_us: "",
    marital_status: "",
    aadhar: "",
    part_time: "",
    experience: "",
    // job_fair_location: "",
    current_industry: "",
    // preffered_work: "",
    skills: "",
    self_opportunity: "",
    wa_optin: "false",
    // center_id: "",
    // center_head: "",
    // center_employee: "",
    // stay_mah: {
    //   year: null,
    //   month: null,
    // },
    // if_disabled: {
    //   nature: "",
    //   percentage: null,
    // },
    // ex_serviceman: {
    //   type: "",
    //   rank: "",
    //   total_service: null,
    // },
    languages: {
      marathi: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
      hindi: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
      english: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
    },
    // wiling_for: {
    //   state_police_service: false,
    //   state_reserve_police_force: false,
    //   armed_force: false,
    //   private_security_services: false,
    //   sales_service: false,
    //   type_of_agents: false,
    //   sweeper_cleaner: false,
    //   shop_customer_services: false,
    // },
    // qualification_and_training: [
    //   {
    //     exam_passed: "",
    //     year: null,
    //     medium: "",
    //     percentage: null,
    //   },
    // ],
  });
  // const [joblocation, setjobLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [desi, setDesi] = useState([]);
  // const [state, setState] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [steps, setSteps] = useState([
    "Personal Information",
    "Career Profile Information",
    "Select Courses",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [jdModal, setJDModal] = useState(false);
  const [jd, setJd] = useState("");
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob();
    element.href = jd.brochure;
    element.download = `${jd.brochure}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // setTimeout(() => {
    //   // showAlert();

    // }, 5000);

    Swal.fire({
      icon: "success",
      title: "Download Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const stepperStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // const [workExp, setWorkExp] = useState([
  //   {
  //     employer_name: "",
  //     designation: "",
  //     job_type: "",
  //     joining_date: "",
  //     relieving_date: "",
  //     totel_exp: null,
  //   },
  // ]);

  // const [educationQ, setEducationQ] = useState([
  //   {
  //     exam_passed: "",
  //     year: null,
  //     medium: "",
  //     percentage: null,
  //   },
  // ]);
  const SBox6 = styled(Box)(
    matched
      ? {
          p: 3,
          ml: "auto",
          mr: "auto",
          background: "rgba(212, 229, 255, 0.45)",
          borderRadius: "9px",
          border: "3px dashed rgba(45, 91, 255, 1)",
          width: "470px",
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          cursor: "pointer",
        }
      : {
          p: 3,
          ml: "auto",
          mr: "auto",
          background: "rgba(212, 229, 255, 0.45)",
          borderRadius: "9px",
          border: "3px dashed rgba(45, 91, 255, 1)",
          width: "300px",
          height: "200px",
          minHeight: "230px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          cursor: "pointer",
        }
  );

  {
    /************ */
  }
  // const base_url =
  //   "https://i60vrmt4cl.execute-api.ap-south-1.amazonaws.com/dev";
  const base_url = process.env.REACT_APP_BACKEND_GENERIC_URL;

  const uat_url = process.env.REACT_APP_BACKEND_GENERIC_URL;
  // "https://qgyoznpzm9.execute-api.ap-south-1.amazonaws.com/uat-present";
  {
    /*Options data */
  }
  const [genderOption, setGenderOption] = useState([]);
  const [educationOption, setEducationOption] = useState([]);
  const [degreeOption, setDegreeOption] = useState([]);
  const [streamOption, setStreamOption] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [casteOption, setCasteOption] = useState([]);
  const [experienceOption, setExperienceOption] = useState([]);
  const [maritalstatusList, setMaritalStatusList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [State, setState] = useState([]);
  const [District, setDistrict] = useState([]);
  const [Taluka, setTaluka] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [jobLocationList, setJobLocationList] = useState([]);
  const [verifyStatus, setVerifyStatus] = useState(false);
  // const [centersList,setCentersList] = useState([]);
  // const [centerHeadList,setCenterHeadList] = useState([]);
  // const [centerEmployeeList,setCenterEmployeeList] = useState([]);

  //// education,degree,stream,jobrole,joblocation,state,district,taluka
  {
    /*Options data */
  }

  const colName = [
    {
      title: "Course Name",
      dataIndex: "course",
      width: 200,
      //fixed: "left",
      key: "course",
      sorter: {
        compare: (a, b) => a?.course?.localeCompare(b?.course),
        multiple: 1,
      },
    },
    {
      title: "Institute",
      dataIndex: "institue",
      width: 200,
      key: "institue",
      sorter: {
        compare: (a, b) => a?.institue?.localeCompare(b?.institue),
        multiple: 2,
      },
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      width: 200,
      key: "qualification",
      sorter: {
        compare: (a, b) => a?.qualification?.localeCompare(b?.qualification),
        multiple: 3,
      },
    },
    {
      title: "Course Duration",
      dataIndex: "duration",
      width: 200,
      key: "duration",
      sorter: {
        compare: (a, b) => a.duration - b.duration,
        multiple: 4,
      },
      render: (text) => {
        return <>{`${text} days`}</>;
      },
    },
    {
      title: "Age",
      dataIndex: "age",
      width: 200,
      key: "age",
      sorter: {
        compare: (a, b) => a?.age?.localeCompare(b?.age),
        multiple: 5,
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      width: 200,
      key: "start_date",
      sorter: {
        compare: (a, b) => a?.start_date?.localeCompare(b?.start_date),
        multiple: 6,
      },
    },
    {
      title: "Course Fees",
      dataIndex: "fees",
      width: 200,
      key: "fees",
      sorter: {
        compare: (a, b) => a.fees - b.fees,
        multiple: 7,
      },
      render: (text) => {
        return <>&#x20B9; {`${text}`}</>;
      },
    },
    {
      title: "SPOC Name",
      dataIndex: "name",
      width: 200,
      key: "name",
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
        multiple: 8,
      },
    },
    {
      title: "SPOC Email",
      dataIndex: "email",
      width: 200,
      key: "email",
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 9,
      },
    },
    {
      title: "SPOC Mobile No",
      dataIndex: "mobile",
      width: 200,
      key: "mobile",
      sorter: {
        compare: (a, b) => a.mobile - b.email,
        multiple: 10,
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 200,
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 11,
      },
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 200,
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 12,
      },
    },
    {
      title: "Taluka",
      dataIndex: "taluka",
      key: "taluka",
      width: 200,
      sorter: {
        compare: (a, b) => a?.taluka?.localeCompare(b?.taluka),
        multiple: 13,
      },
    },
    { title: "Address", dataIndex: "address", key: "address", width: 200 },
    {
      title: "Course Description",
      // dataIndex: "discription",
      width: 200,
      key: "discription",
      // sorter: {
      //   compare: (a, b) => a?.discription?.localeCompare(b?.discription),
      //   multiple: 2,
      // },
      render: (text) => (
        <p
          style={{
            textDecoration: "underline",
            color: "#3B5092",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => (setJDModal(true), setJd(text))}
        >
          View
        </p>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: selectCourse.includes(record.key),
      key: record.key,
    }),
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const courseList = async () => {
    await axios
      .get(`${uat_url}/courses/course_register`)
      // axios
      //   .get(`https://samarth.uat.api.globalsapio.com/courses/course_register`)
      .then((res) => {
        // setDistrict(res.data);
        setIsLoading(false);
        console.log("Role tabel", res.data);
        setCourseData(
          res.data.map((ele, id) => {
            return {
              key: ele.course_id,
              course: ele.name,
              institue: ele.org_name ? ele.org_name : " - ",
              qualification: ele.highest_qualification,
              duration: ele.duration,
              age:
                ele.age_lower_range !== null && ele.age_upper_range !== null
                  ? `${ele.age_lower_range} to ${ele.age_upper_range}`
                  : " - ",
              discription: ele.description,
              mode_of_course: ele.mode_of_course,
              start_date: ele.start_date
                ? moment(ele.start_date).format("DD-MM-YYYY")
                : " - ",
              fees: ele.fees && ele.fees !== "" ? ele.fees : 0,
              name: ele.spoc_name,
              email: ele.spoc_email,
              mobile: ele.spoc_mobile,
              state: ele.state,
              district: ele.district,
              taluka: ele.taluka,
              address: ele.address,
              brochure: ele.brochure,
              enabled: ele.duration === 5 ? false : true,
            };
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    // https://samarth.uat.api.globalsapio.com
  };

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        "https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/desg"
      );
      const data = response.data;
      setDesi(
        Object.values(data.name).map((ele) => ({
          label: ele,
          value: ele,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchCenterHead = async ()=>{
  //   axios.get(`${base_url}/centerhead/master/details`)
  //   .then((res)=>{
  //     console.log("cH data",res.data["center_head_details"])
  //     setCenterHeadList(res.data["center_head_details"])
  //   })
  //   .catch((err)=>{
  //     console.log("error ch",err.response);
  //   })
  // }
  // const fetchCenters = async ()=>{
  //   axios.get(`${base_url}/jobcenter/master/details`)
  //   .then((res)=>{
  //     console.log("center data",res.data["job_center_details"])
  //     setCentersList(res.data["job_center_details"])
  //   })
  //   .catch((err)=>{
  //     console.log("error center",err.response);
  //   })
  // }
  // const fetchCenterEmployee = async ()=>{
  //   axios.get(`${base_url}/centeremployee/master/details`)
  //   .then((res)=>{
  //     console.log("center data",res.data["center_employee_details"])
  //     setCenterEmployeeList(res.data["center_employee_details"]);
  //   })
  //   .catch((err)=>{
  //     console.log("error center",err.response);
  //   })
  // }

  const fetchState = async () => {
    axios
      .get(`${base_url}/generic/state`)
      .then((res) => {
        console.log("state", res.data);
        setState(res.data);
      })
      .catch((e) => {
        console.log("state", e);
      });
  };

  const fetchDistrict = async () => {
    axios
      .get(`${base_url}/generic/district`, {
        params: { state: selectedState },
      })
      .then((res) => {
        console.log("district", res.data);
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log("district", err);
      });
  };

  const syncWait = (ms) => {
    const end = Date.now() + ms;
    while (Date.now() < end) continue;
  };

  /*
  const fetchTaluka = async () => {
    axios
      .get(`${base_url}/generic/taluka`, {
        params: {
          state: formDetails.state,
          district: formDetails.district,
        },
      })
      .then((res) => {
        console.log("taluka", res.data);
        setTaluka(res.data);
      })
      .catch((err) => {
        console.log("taluka", err);
      });
  };
  */

  const fetchDegree = async () => {
    axios
      .get(`${base_url}/generic/degree`)
      .then((res) => {
        console.log("Degree", res.data);
        setDegreeOption(res.data);
      })
      .catch((e) => {
        console.log("degree", e);
      });
  };
  const fetchStream = async () => {
    axios
      .get(`${base_url}/generic/stream`)
      .then((res) => {
        console.log("Stream", res.data);
        setStreamOption(res.data);
      })
      .catch((e) => {
        console.log("stream", e);
      });
  };
  const fetchSalary = async () => {
    axios
      .get(`${base_url}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };
  const fetchGender = async () => {
    axios
      .get(`${base_url}/generic/gender`)
      .then((res) => {
        console.log("gender", res.data);
        setGenderOption(res.data);
      })
      .catch((err) => {
        console.log("gender", err);
      });
  };
  const fetchEducation = async () => {
    axios
      .get(`${base_url}/generic/qualification`)
      .then((res) => {
        console.log("education", res.data);
        setEducationOption(res.data);
      })
      .catch((err) => {
        console.log("education", err);
      });
  };
  const fetchIndustry = async () => {
    axios
      .get(`${base_url}/generic/industry`)
      .then((res) => {
        console.log("industry", res.data);
        setIndustryOption(res.data);
      })
      .catch((err) => {
        console.log("industry", err);
      });
  };
  const fetchCaste = async () => {
    axios
      .get(`${base_url}/generic/caste`)
      .then((res) => {
        console.log("caste", res.data);
        setCasteOption(res.data);
      })
      .catch((err) => {
        console.log("caste", err);
      });
  };
  const fetchExperience = async () => {
    axios
      .get(`${base_url}/generic/experience`)
      .then((res) => {
        console.log("experience", res.data);
        const data = res.data;
        setExperienceOption(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("experience", err);
      });
  };
  const fetchJobRole = async () => {
    axios
      .get(`${base_url}/generic/designation`)
      .then((res) => {
        console.log("job_role", res.data);
        const data = res.data;
        setJobRoleList(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("job_role", err);
      });
  };
  const fetchJobLocationList = async () => {
    axios
      .get(`${base_url}/jobseeker/fetch_jf_location`)
      .then((res) => {
        console.log("job_location", res.data.job_fair_location);
        const data = res.data;
        setJobLocationList(data.job_fair_location);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("jf_location", err);
      });
  };
  const fetchMaritalStatus = async () => {
    axios
      .get(`${base_url}/generic/maritalstatus`)
      .then((res) => {
        console.log(res.data);
        setMaritalStatusList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchSkillsList = async () => {
    axios
      .get(`${base_url}/generic/skills`)
      .then((res) => {
        console.log(res.data);
        setSkillsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLink = async () => {
    // try {
    //   const response = await axios.post(
    //     "https://m1fk502650.execute-api.ap-south-1.amazonaws.com/uat/superadmin/dashboard/jobcenter/fetch/details",
    //     {
    //       registration_id: query1,
    //     }
    //   );
    //   const data = response.data;
    //   console.log(data);
    // } catch (err) {
    //   console.log(err);
    // }
    axios
      .post(
        "https://m1fk502650.execute-api.ap-south-1.amazonaws.com/uat/superadmin/dashboard/jobcenter/fetch/details",
        {
          registtration_id: query1,
        }
      )
      .then((res) => {
        console.log("link data ", res.data);
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  const fetchJobRoleNew = async () => {
    axios
      .get(`${base_url}/jobfair/CPdesignation`, {
        params: { cpid: searchParams.get("cpid") },
      })
      .then((res) => {
        console.log("job_role", res.data);
        const data = res.data;
        setJobRoleList(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("job_role", err);
      });
  };

  useEffect(() => {
    // fetchCenterHead();
    // fetchCenterEmployee();
    // fetchCenters();
    // fetchData1();
    //fetchData2()
    fetchGender();
    fetchEducation();
    fetchExperience();
    fetchCaste();
    fetchIndustry();
    // fetchJobRole();
    fetchJobRoleNew();
    fetchJobLocationList();
    fetchDegree();
    fetchStream();
    fetchSalary();
    fetchMaritalStatus();
    fetchSkillsList();
  }, []);

  const fetchlang = async () => {
    try {
      const res = await axios.get(
        "https://samarth.prod.api.sapioglobal.com/jobseeker/account/language"
      );
      const data = await res.data;
      setLanguageList(
        data?.map((v) => {
          return {
            value: v,
            label: v,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchState();
    fetchlang();
    // fetchQuesList();
  }, []);

  useEffect(() => {
    fetchDistrict();
  }, [selectedState]);

  /*
  useEffect(() => {
    fetchTaluka();
  }, [formDetails.district]);
  */

  useEffect(() => {
    show === 2 && courseList();
  }, [show]);

  {
    /************ */
  }

  const addFields = (e) => {
    e.preventDefault();
    let newfield = {
      employer_name: "",
      designation: "",
      job_type: "",
      joining_date: "",
      relieving_date: "",
      totel_exp: null,
    };

    // setWorkExp([...workExp, newfield]);
  };

  // useEffect(() => {
  //   const fetchStateList = () => {
  //     axios
  //       .post(
  //         ` https://dashboardapi.mysba.globalsapio.com/State`,
  //         {
  //           level: 1,
  //           thislevel: "India",
  //           locale: "en",
  //         }
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${token}`,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         // console.log("State", res.data);
  //         // var StateList = res.data[0].sort().map(function (item) {
  //         //   return { label: item, value: item };
  //         // });
  //         setState(res.data);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   };
  //   fetchStateList();
  // }, []);

  // useEffect(() => {
  //   const fetchstate = () => {
  //     axios
  //       .post(
  //         `https://dashboardapi.mysba.globalsapio.com/District`,
  //         {
  //           level: 2,
  //           thislevel: formDetails.state,
  //           locale: "en",
  //         }
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${token}`,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         setDistrictList(res.data);

  //         //console.log("District", res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   //fetchStateList();
  //   fetchstate();
  // }, [formDetails]);

  const handleFormChange = (index, event) => {
    // let data = [...workExp];
    // data[index][event.target.name] = event.target.value;
    // setWorkExp(data);
  };
  const removeFields = (index) => {
    // let data = [...workExp];
    // data.splice(index, 1);
    // setWorkExp(data);
  };

  const addFields1 = (e) => {
    e.preventDefault();
    let newfield = {
      exam_passed: "",
      year: null,
      medium: "",
      percentage: null,
    };

    // setEducationQ([...educationQ, newfield]);
  };
  const handleFormChange1 = (index, event) => {
    // let data = [...educationQ];
    // data[index][event.target.name] = event.target.value;
    // setEducationQ(data);
  };
  const removeFields1 = (index) => {
    // let data = [...educationQ];
    // data.splice(index, 1);
    // setEducationQ(data);
  };

  const formMinusOne = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobnumber: "",
    },
    validationSchema: Yup.object().shape({
      mobnumber: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setMobilenum(values.mobnumber);
      try {
        const res = await axios.post(
          `${uat_url}/channelpartner/dashboard/candidate/skilling`,
          {
            type: "c",
            candidate_id: Number(values.mobnumber),
          }
        );
        const data = await res.data;
        console.log(data);
        setCandidateId(data?.registration_id);
        setSelectCourse(data?.course_id?.length === 0 ? [] : data?.course_id);
        setHide(true);
        setShow(2);
        courseList();
        setMobilenum("");
        //formMinusOne.resetForm()
      } catch (err) {
        if (err?.response?.data?.is_exist === 0) {
          console.log(err);
          setSelectCourse([]);
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
          setSelectCourse([]);
        }
        setHide(false);
        setShow(0);
      }
    },
  });

  const formOne = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstnm: "",
      lastnm: "",
      email: "",
      mobnum: mobilenum === "" ? "" : mobilenum,
      birth_date: "",
      gender: "",
      marital_status: "",
      org_address: "",
      org_state: "",
      org_district: "",
      taluka: "",
      org_pincode: "",
    },
    validationSchema: Yup.object().shape({
      org_address: Yup.string().required("Please enter address"),
      org_state: Yup.string().required("Please select state"),
      org_district: Yup.string().required("Please select district"),
      org_pincode: Yup.string()
        .notRequired()
        .length(6, "Pincode should be 6 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      //industry: Yup.string().required("Please select industry"),
      email: Yup.string().notRequired().email("Please enter valid email"),
      mobnum: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
      gender: Yup.string().required("Please select gender"),
      birth_date: Yup.string().required("Please enter date of birth"),
    }),
    onSubmit: async (values) => {
      setOpen1(true);
      try {
        const params = {
          mobile: values.mobnum,
        };
        const paramString = new URLSearchParams(params).toString();
        const res = await axios.get(
          `${uat_url}/jobseeker/fetch_jc_data?${paramString}`
        );
        if (res.data) {
          setOpen1(false);
        }

        Swal.fire({
          icon: "info",
          title: "",
          text: "Try new mobile number",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (err) {
        try {
          syncWait(2000);
          setObj({
            org_address: values.org_address,
            org_state: values.org_state,
            org_district: values.org_district,
            taluka: values.taluka,
            org_pincode: values.org_pincode,
            mobnum: values.mobnum,
            email: values.email,
            firstnm: values.firstnm,
            lastnm: values.lastnm,
            marital_status: values.marital_status,
            gender: values.gender,
            birth_date: values.birth_date,
          });
          /*
          let formdata = new FormData()
          formdata.append("org_address", values.org_address)
          formdata.append("org_state", values.org_state)
          formdata.append("org_district", values.org_district)
          formdata.append("org_pincode", values.org_pincode)
          formdata.append("industry", "-")
          formdata.append("mobnum", values.mobnum)
          formdata.append("email", values.email)
          formdata.append("firstnm", values.firstnm)
          formdata.append("lastnm", values.lastnm)
          formdata.append("attested", 1)
          formdata.append("form_part", 1)
          */
          //setRegId(data['Developer Message'] ? data['Developer Message'] : "")

          setSubmitformOne(false);
          setOpen1(false);
          setNextStep(true);
          // setShow(true);
          setShow(1);
          handleNext();
        } catch (err) {
          console.log(err);
          setOpen1(false);
          setNextStep(false);
          setSubmitformOne(false);
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Something went wrong.",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            setNextStep(false);
          });
        }
      }
    },
  });

  const formTwo = useFormik({
    initialValues: {
      highest_education_new: "",
      current_industry_new: "",
      current_salary_new: "",
      des_salary_new: "",
      job_role_new: "",
      self_opportunity: "",
      relocation: "",
      experience_new: "",
      stream_new: "",
      //avl_skill: "",
    },
    validationSchema: Yup.object().shape({
      highest_education_new: Yup.string().required(
        "Please select highest education"
      ),
      current_industry_new: Yup.string().required(
        "Please select current industry"
      ),
      job_role_new: Yup.string().required("Please select job role"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (resumeshow === true && resumefile === "") {
        Swal.fire({
          icon: "error",
          html: "<b>Please upload your resume.</b>",
          showConfirmButton: false,
          width: matchsm ? "350" : "300",
          timer: 2000,
        });
      } else {
        try {
          let formdata = new FormData();
          formdata.append("highest_education", values.highest_education_new);
          formdata.append("stream", values.stream_new);
          formdata.append("current_industry", values.current_industry_new);
          formdata.append(
            "skills",
            preffere.map((ele) => ele.value).toString()
          );
          formdata.append("current_salary", values.current_salary_new);
          formdata.append("desired_salary", values.des_salary_new);
          formdata.append("job_role", values.job_role_new);
          formdata.append(
            "languages",
            languagesall.map((ele) => ele.value).toString()
          );
          formdata.append("self_opportunity", values.self_opportunity);
          formdata.append("relocation", values.relocation === "Yes" ? 1 : 0);
          formdata.append("experience", values.experience_new);
          //formdata.append("avl_skill", values.avl_skill)
          formdata.append(
            "avl_skill",
            skillingall.map((ele) => ele.value).toString()
          );
          formdata.append("resume_ready", resumeshow1);
          formdata.append("firstname", obj.firstnm);
          formdata.append("lastname", obj.lastnm);
          formdata.append("email", obj.email);
          formdata.append(
            "date_of_birth",
            moment(obj.birth_date).format("YYYY-MM-DD")
          );
          formdata.append("gender", obj.gender);
          formdata.append("marital_status", obj.marital_status);
          formdata.append("state", obj.org_state);
          formdata.append("district", obj.org_district);
          formdata.append("taluka", obj.taluka);
          formdata.append("mobnum", obj.mobnum);
          formdata.append("address", obj.org_address);
          formdata.append("wa_optin", "true");
          formdata.append("degree", "");
          formdata.append("resume", resumeshow === true ? resumefile : "");
          formdata.append("pincode", obj.org_pincode);
          formdata.append(
            "datasource",
            cpId ? cpId : id === "srs" ? "saksham_portal" : "JC_FORM"
          );
          const res = await axios.post(
            `${uat_url}/channelpartner/dashboard/candidate/skilling`,
            formdata
          );

          const data = await res.data;
          setCandidateId(data.registration_id ? data.registration_id : "");
          //setSubmitform(false);
          //resetForm();
          setSelectedState("");
          fetchLink();
          setOpen(true);
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: err?.response?.data
              ? err.response.data["Developer Message"]
              : "Something went wrong",
            text: err?.response?.data
              ? err.response.data["User Message"]
              : "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
  });

  useEffect(() => {
    const array1 = [
      formOne.values.firstnm,
      formOne.values.lastnm,
      formOne.values.gender,
      formOne.values.birth_date,
      formOne.values.mobnum,
      formOne.values.org_address,
      formOne.values.org_district,
      formOne.values.org_state,
    ];
    const array2 = [
      formTwo.values.highest_education_new,
      formTwo.values.current_industry_new,
      formTwo.values.job_role_new,
    ];
    setProgress(
      show
        ? array2.filter((v) => v !== "")?.map((v) => v)?.length * 16.665 + 50
        : array1.filter((v) => v !== "")?.map((v) => v)?.length * 6.25
    );
  }, [formOne, formTwo, show]);

  useEffect(() => {
    // axios
    //   .get("https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/desg")
    //   .then((res) => {
    //     setDesi(
    //       Object.values(res.data.name).map((ele) => ({
    //         label: ele,
    //         value: ele,
    //       }))
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // axios
    //   .get(`${base_url}/desg`)
    //   .then((res) => {
    //     setDesi(
    //       Object.values(res.data.name).map((ele) => ({
    //         label: ele,
    //         value: ele,
    //       }))
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  const query = searchParams.get("location");
  console.log(query);

  const handleResume = (File) => {
    const validExt = ["pdf", "doc"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (handleResume(file) && file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        html: "<b>Please upload files < 5mb and make sure format is either pdf or doc.</b>",
        showConfirmButton: false,
        width: matchsm ? "350" : "300",
        timer: 3000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  console.log(params);
  // const query1 = searchParams.get("centerid");
  // const query2 = searchParams.get("centerhead");
  // const query3 = searchParams.get("centeremployee");
  const query1 = searchParams.get("jcid");
  const query2 = searchParams.get("chid");
  const query3 = searchParams.get("ceid");
  const cpId = sessionStorage.getItem("cpid");
  const jobId = searchParams.get("jobid");
  const orgId = searchParams.get("orgid");
  const id = sessionStorage.getItem("id");
  const source = sessionStorage.getItem("source");
  console.log("query ", query1, query2, query3);
  console.log(
    "datasource:",
    cpId
      ? cpId
      : id === "srs"
      ? "saksham_portal"
      : id !== "" && id !== null
      ? id
      : source === "null"
      ? "JOB_GATEWAY"
      : source
      ? source
      : // : cpId === null && source === null && jobId !== null
        // ?
        "JC_FORM"
  );
  const checkMobileNum = (number) => {
    const params = {
      mobile: number,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${base_url}/jobseeker/fetch_jc_data?${paramString}`)
      .then((res) => {
        Swal.fire({
          icon: "info",
          title: "",
          text: "Try new mobile number",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log("New user", err);
        handleSubmit();
        // setVerifyStatus(false);
        // return false;
        // Swal.fire({
        //   icon: "error",
        //   title: "",
        //   text: err.data,
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let formData = new FormData();
    // formData.append("location", query);
    formData.append("firstname", formDetails.firstname);
    formData.append("lastname", formDetails.lastname);
    //formData.append("fathername", formDetails.fathername);
    //formData.append("mothername", formDetails.mothername);
    formData.append("email", formDetails.email);
    formData.append(
      "date_of_birth",
      moment(formDetails.date_of_birth).format("YYYY-MM-DD")
      // formDetails.date_of_birth !== ""
      //   ? formDetails.date_of_birth.split("-").reverse().join("-")
      //   : ""
    );
    formData.append("gender", formDetails.gender);
    formData.append("marital_status", formDetails.marital_status);
    formData.append("state", formDetails.state);
    //formData.append("part_time", formDetails.part_time);
    formData.append("district", formDetails.district);
    formData.append("taluka", formDetails.taluka);
    // formData.append("know_us", formDetails.know_us);
    formData.append("self_opportunity", formDetails.self_opportunity);
    formData.append("highest_education", formDetails.highest_education);
    formData.append("stream", formDetails.stream);
    formData.append("mobnum", formDetails.mobile);
    //formData.append("caste_category", formDetails.cast);
    // formData.append("aadhar", formDetails.aadhar);
    formData.append("current_industry", formDetails.current_industry);
    formData.append("address", formDetails.address);
    // formData.append(
    //   "preffered_work",
    //   preffere.map((ele) => ele.value).toString()
    // );
    formData.append("skills", preffere.map((ele) => ele.value).toString());
    formData.append("experience", formDetails.experience);
    formData.append("des_industry", formDetails.des_industry);
    formData.append("wa_optin", "true");
    formData.append(
      "languages",
      languagesall.map((ele) => ele.value).toString()
    );
    // formData.append(
    //   "languages",
    //   "Hindi English"
    // );
    formData.append("desired_salary", formDetails.desired_salary);
    formData.append("current_salary", formDetails.current_salary);
    formData.append("resume", resumefile);
    // formData.append("datetime", new Date());
    // formData.append("date", newdate);
    // formData.append("time", new Date().toLocaleTimeString());
    formData.append(
      "datasource",
      cpId ? cpId : id === "srs" ? "saksham_portal" : "JC_FORM"
    );
    formData.append("pincode", formDetails.pincode);
    // formData.append("center_id",formDetails.center_id);
    // formData.append("center_head", formDetails.center_head);
    formData.append("job_role", formDetails.job_role);
    cpId && formData.append("jobid", jobId);
    cpId && formData.append("orgid", orgId);
    !cpId && formData.append("center_id", query1);
    !cpId && formData.append("center_head", query2);
    !cpId && formData.append("center_employee", query3);

    ///
    {
      /*
      formData.append("pincode",formDetails.pincode)
      formData.append("datasource","JC_FORM");
      formData.append("center_id","JC10"),
    formData.append("center_head", "CH2"),
    formData.append("center_employee", "CE5",)
    */
    }

    await axios
      .post(
        // " https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/mysbajobfair",
        // `${uat_url}/jobseeker/register/job_center`,
        `${uat_url}/channelpartner/dashboard/candidate/form`,
        formData
        // {
        //   firstname: formDetails.firstname,
        //   lastname: formDetails.lastname,
        //   fathername: formDetails.fathername,
        //   mothername: formDetails.mothername,
        //   // height:formDetails.height,
        //   email: formDetails.email,
        //   date_of_birth: formDetails.date_of_birth,
        //   gender: formDetails.gender,
        //   marital_status: formDetails.marital_status,
        //   State: formDetails.state,
        //   // mother_tongue:formDetails.mother_tongue,
        //   // stay_mah:formDetails.stay_mah,
        //   part_time: formDetails.part_time,
        //   district: formDetails.district,
        //   taluka: formDetails.taluka,
        //   know_us: formDetails.know_us,
        //   // religion:formDetails.religion,
        //   qualification: formDetails.qualification,
        //   stream: formDetails.stream,
        //   mobile: formDetails.mobile,
        //   cast: formDetails.cast,
        //   // sub_cast: formDetails.sub_cast,
        //   aadhar: formDetails.aadhar,
        //   // priority:formDetails.priority,
        //   // job_fair_location: formDetails.job_Location,
        //   current_industry: formDetails.current_industry,
        //   address: formDetails.address,
        //   preffered_work: preffere.map((ele) => ele.value).toString(),
        //   experience: formDetails.experience,
        //   des_industry: formDetails.des_industry,
        //   // if_disabled:formDetails.if_disabled,
        //   // ex_serviceman:formDetails.ex_serviceman,
        //   wa_optin: check ? 1 : 0,
        //   // experience: workExp,
        //   // qualification_and_training: educationQ,
        //   // wiling_for:formDetails.wiling_for,
        //   languages: formDetails.languages,
        // }
      )
      .then((res) => {
        // console.log(res.response.data, "Line 78")
        // alert("form Submitted");
        setIsLoading(false);
        fetchLink();
        Swal.fire({
          icon: "success",
          title: "",
          text: "Details Submited Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setFormDetails({
          firstname: "",
          lastname: "",
          //fathername: "",
          //mothername: "",
          religion: "",
          mother_tongue: "",
          email: "",
          date_of_birth: "",
          gender: "",
          height: "",
          address: "",
          state: "",
          priority: "",
          district: "",
          taluka: "",
          desired_salary: "",
          highest_education: "",
          des_industry: "",
          stream: "",
          mobile: "",
          cast: "",
          job_Location: "",
          // sub_cast: "",
          marital_status: "",
          aadhar: "",
          know_us: "",
          part_time: "",
          // job_fair_location: "",
          current_industry: "",
          preffered_work: "",
          wa_optin: "false",
          self_opportunity: "",
          // center_id:"",
          // center_head:"",
          // center_employee:"",
          experience: "",
          // stay_mah: {
          //   year: null,
          //   month: null,
          // },
          // if_disabled: {
          //   nature: "",
          //   percentage: null,
          // },
          // ex_serviceman: {
          //   type: "",
          //   rank: "",
          //   total_service: null,
          // },
          languages: {
            marathi: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
            hindi: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
            english: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
          },
          // wiling_for: {
          //   state_police_service: false,
          //   state_reserve_police_force: false,
          //   armed_force: false,
          //   private_security_services: false,
          //   sales_service: false,
          //   type_of_agents: false,
          //   sweeper_cleaner: false,
          //   shop_customer_services: false,
          // },
        });
        //setCheck(true);
        // setjobLocation([]);
        setResumeFile("");
        setPreffere([]);
        setLanguagesall([]);
        // setWorkExp([
        //   {
        //     employer_name: "",
        //     designation: "",
        //     job_type: "",
        //     joining_date: "",
        //     relieving_date: "",
        //     totel_exp: null,
        //   },
        // ]);
        // setEducationQ([
        //   {
        //     exam_passed: "",
        //     year: null,
        //     medium: "",
        //     percentage: null,
        //   },
        // ]);
      })

      .catch((err) => {
        console.log(err.response.data["Developer Message"]);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "",
          text: err.response.data["Developer Message"],
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  // useEffect( ()=>{()
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });

  const FinalSubmit = async () => {
    if (selectedRowKeys?.length > 3) {
      setOpen4(true);
    } else {
      try {
        const res = await axios.post(`${uat_url}/courses/course_shortlist`, {
          course_id: selectedRowKeys,
          //orgid: "",
          shortlistedby: candidateId,
          candidate_ids: [candidateId],
        });
        const data = await res.data;
        Swal.fire({
          icon: "success",
          title: "Submitted Successfully",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          setSelectCourse([]);
          //window.location.reload();
          setHide(true);
          setSubmitform(false);
          formTwo.handleReset();
          formOne.handleReset();
          formMinusOne.handleReset();
          setNextStep(false);
          handleReset();
          setResumeFile("");
          setResumeshow(false);
          setResumeshow1(0);
          setLanguagesall([]);
          setPreffere([]);
          setSkillingall([]);
          setCandidateId("");
          setSelectedRowKeys([]);
          setShow(-1);
        });
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const options = [
    {
      label: "Favourites",
      options: fav,
    },
    {
      label: "Others",
      options: desi,
    },
    // { label: "A root option", value: "value_3" },
    // { label: "Another root option", value: "value_4" },
  ];
  return (
    <>
      <div>
        <div>
          <img
            style={{ width: "100%", height: "auto" }}
            // src={cover1}
            src={
              bannerState !== ""
                ? bannerState
                : sessionStorage.getItem("id") === "mysba"
                ? mysba
                : sessionStorage.getItem("id") === "srs"
                ? saksham
                : sessionStorage.getItem("id") === "admins"
                ? adminsBanner
                : sessionStorage.getItem("id") === "Vakrangee"
                ? vkmsBanner
                : sessionStorage.getItem("id") === "hindurojgaar"
                ? hindurojgarBanner
                : saksham
            }
            className="logo-img"
          />
          {/* <img
            style={{ width: "100%", height: "auto" }}
            src={cover1}
            className="logo-img1"
          /> */}
        </div>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div className="SDiv">
            {show === 1 && (
              <>
                <SBox2>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <STypography2 variant="body1">Registration</STypography2>

                    {matchsm ? (
                      <img
                        src={img1}
                        style={{ height: "53.13px", margin: "8px" }}
                        alt=""
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "700px",
                      minWidth: "200px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    <Stepper activeStep={activeStep} sx={stepperStyle}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <STypography7 variant="body2">
                                {label}
                              </STypography7>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "700px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    <ProgressBar
                      variant="success"
                      now={Math.round(progress)}
                      label={`${Math.round(progress)}%`}
                    />
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Highest Education{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={educationOption}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "highest_education_new",
                                e.value
                              );
                            }}
                            value={{
                              label: formTwo.values.highest_education_new,
                            }}
                            id="highest_education_new"
                            name="highest_education_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.highest_education_new &&
                        formTwo.errors.highest_education_new ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.highest_education_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Stream{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={streamOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("stream_new", e.value);
                            }}
                            value={{ label: formTwo.values.stream_new }}
                            id="stream_new"
                            name="stream_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Current Industry{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={industryOption}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "current_industry_new",
                                e.value
                              );
                            }}
                            value={{
                              label: formTwo.values.current_industry_new,
                            }}
                            id="current_industry_new"
                            name="current_industry_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.current_industry_new &&
                        formTwo.errors.current_industry_new ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.current_industry_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Experience{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={experienceOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("experience_new", e.value);
                            }}
                            value={{ label: formTwo.values.experience_new }}
                            id="experience_new"
                            name="experience_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Skills{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            options={skillsList}
                            isMulti
                            value={preffere}
                            onChange={(e) => {
                              if (e) {
                                setPreffere(e.map((item) => item));
                              } else {
                                setPreffere([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Current Salary{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={salaryList}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "current_salary_new",
                                e.value
                              );
                            }}
                            value={{ label: formTwo.values.current_salary_new }}
                            id="current_salary_new"
                            name="current_salary_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Desired Salary{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={salaryList}
                            onChange={(e) => {
                              formTwo.setFieldValue("des_salary_new", e.value);
                            }}
                            value={{ label: formTwo.values.des_salary_new }}
                            id="des_salary_new"
                            name="des_salary_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Desired Job Role{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={jobRoleList}
                            onChange={(e) => {
                              formTwo.setFieldValue("job_role_new", e.value);
                            }}
                            value={{ label: formTwo.values.job_role_new }}
                            id="job_role_new"
                            name="job_role_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.job_role_new &&
                        formTwo.errors.job_role_new ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.job_role_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Available skilling opportunities{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            isMulti
                            options={newopportunities}
                            value={skillingall}
                            onChange={(e) => {
                              if (e) {
                                setSkillingall(e.map((item) => item));
                              } else {
                                setSkillingall([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Languages Known{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            isMulti
                            options={languageList}
                            value={languagesall}
                            onChange={(e) => {
                              if (e) {
                                setLanguagesall(e.map((item) => item));
                              } else {
                                setLanguagesall([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Interested in self employement opportunity?{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={opportunities}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "self_opportunity",
                                e.value
                              );
                            }}
                            value={{ label: formTwo.values.self_opportunity }}
                            id="self_opportunity"
                            name="self_opportunity"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Ready to relocate?{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={opportunities}
                            onChange={(e) => {
                              formTwo.setFieldValue("relocation", e.value);
                            }}
                            value={{ label: formTwo.values.relocation }}
                            id="relocation"
                            name="relocation"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Do you have your resume?{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            options={opportunities}
                            onChange={(e) => {
                              setResumeshow(
                                e ? (e.label === "Yes" ? true : false) : false
                              );
                              setResumeshow1(
                                e ? (e.label === "Yes" ? 1 : 0) : 0
                              );
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>
                  </Grid>
                  <br />

                  {resumeshow ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        id="imgupload"
                        ref={ref}
                        style={{ display: "none" }}
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="imgupload">
                        {resumefile === "" ? (
                          <SBox6>
                            <img src={img2} height={"60px"} alt="" />

                            <STypography8 variant="body1">
                              Choose File+
                            </STypography8>

                            <STypography9 variant="body1">
                              {
                                "Please upload files < 5mb and make sure format is either pdf or doc."
                              }
                            </STypography9>
                          </SBox6>
                        ) : (
                          <SBox6>
                            <img src={img4} height={"70px"} alt="" />

                            <STypography8 variant="body1">
                              File Uploaded
                            </STypography8>
                          </SBox6>
                        )}
                      </label>
                    </Box>
                  ) : null}

                  {resumeshow === false ? (
                    <STypography10 variant="body1">
                      "If you already have a CV, please upload. If not, we will
                      prepare your Saksham powered CV and may share upon
                      request."
                    </STypography10>
                  ) : null}

                  <Box
                    sx={{
                      pt: 1,
                      pb: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="checkbox"
                      style={{ marginBottom: "8px" }}
                      checked={true}
                    />

                    <img
                      src={watsapp}
                      style={{
                        height: "30px",
                        width: "30px",
                        marginBottom: "8px",
                      }}
                    />

                    <STypography4 variant="p">
                      &nbsp;I give the consent to share my details and I allow
                      to send me further details and updates over WhatsApp.
                    </STypography4>
                  </Box>

                  <Box sx={{ p: 1, mt: 1 }}>
                    {/* <SButton1  */}
                    <SButton1 onClick={formTwo.handleSubmit}>
                      <STypography6 variant="body1">Submit</STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        // setShow(false);
                        setShow(1);
                        //setSubmitform(false);
                        formTwo.handleReset();
                        //formOne.handleReset();
                        setNextStep(false);
                        handleReset();
                        setShow(0);
                        setResumeFile("");
                        setResumeshow(false);
                        setResumeshow1(0);
                        setLanguagesall([]);
                        setPreffere([]);
                        setSkillingall([]);
                      }}
                    >
                      <STypography6 variant="body1">Back</STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            )}

            {show === 2 && (
              <>
                <SBox2>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <STypography2 variant="body1">Registration</STypography2>

                    {matchsm ? (
                      <img
                        src={img1}
                        style={{ height: "53.13px", margin: "8px" }}
                        alt=""
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      maxWidth: "700px",
                      minWidth: "200px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    {hide ? null : (
                      <Stepper activeStep={activeStep} sx={stepperStyle}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>
                                <STypography7 variant="body2">
                                  {label}
                                </STypography7>
                              </StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                    )}
                  </Box>
                  <Box sx={{ height: "100" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                    >
                      Candidate ID:{" "}
                      <span style={{ color: "#093C8E" }}>
                        {candidateId ? candidateId : ""}
                      </span>
                    </Typography>
                  </Box>
                  <br />

                  {/*
                  {hide ?
                    <Box sx={{ height: "100", display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "20px",
                        }}
                      >
                        Select Courses
                      </Typography>
                    </Box> :
                    null
                  }
                  */}

                  <Grid container spacing={2}>
                    <Grid lg={12} sm={12}>
                      <Box p={2} sx={{ width: "1000px", overflow: "auto" }}>
                        <Box>
                          <Table
                            dataSource={courseData}
                            rowSelection={rowSelection}
                            columns={colName}
                            // width={2000}
                            scroll={{ x: 300, y: 400 }}
                            rowClassName={(record) =>
                              selectCourse.includes(record.key) &&
                              "disabled-row"
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1 onClick={() => FinalSubmit()}>
                      <STypography6 variant="body1">Submit</STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        // setShow(false);
                        setShow(-1);
                        setHide(true);
                        setSubmitform(false);
                        formTwo.handleReset();
                        formOne.handleReset();
                        formMinusOne.handleReset();
                        setNextStep(false);
                        handleReset();
                        setResumeFile("");
                        setResumeshow(false);
                        setResumeshow1(0);
                        setLanguagesall([]);
                        setPreffere([]);
                        setSkillingall([]);
                        setCandidateId("");
                        setSelectCourse([]);
                      }}
                    >
                      <STypography6 variant="body1">
                        Go Back to Home
                      </STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            )}

            {show === 0 && (
              <>
                <SBox2>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <STypography2 variant="body1">Registration</STypography2>

                    {matchsm ? (
                      <img
                        src={img1}
                        style={{ height: "53.13px", margin: "8px" }}
                        alt=""
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "700px",
                      minWidth: "200px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    <Stepper activeStep={activeStep} sx={stepperStyle}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <STypography7 variant="body2">
                                {label}
                              </STypography7>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "700px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    <ProgressBar
                      variant="success"
                      now={Math.round(progress)}
                      label={`${Math.round(progress)}%`}
                    />
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          First Name{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="firstnm"
                          name="firstnm"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.firstnm}
                        />

                        {formOne.touched.firstnm && formOne.errors.firstnm ? (
                          <STypography5 variant="caption">
                            {formOne.errors.firstnm}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Last Name{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="lastnm"
                          name="lastnm"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.lastnm}
                        />

                        {formOne.touched.lastnm && formOne.errors.lastnm ? (
                          <STypography5 variant="caption">
                            {formOne.errors.lastnm}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Email{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="email"
                          fullWidth
                          id="email"
                          name="email"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.email}
                        />

                        {formOne.touched.email && formOne.errors.email ? (
                          <STypography5 variant="caption">
                            {formOne.errors.email}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Mobile Number{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="tel"
                          fullWidth
                          id="mobnum"
                          name="mobnum"
                          size="small"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "10",
                            minLength: "10",
                          }}
                          onChange={formOne.handleChange}
                          value={formOne.values.mobnum}
                        />

                        {formOne.touched.mobnum && formOne.errors.mobnum ? (
                          <STypography5 variant="caption">
                            {formOne.errors.mobnum}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Date of Birth{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="date"
                          fullWidth
                          id="birth_date"
                          name="birth_date"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.birth_date}
                        />

                        {formOne.touched.birth_date &&
                        formOne.errors.birth_date ? (
                          <STypography5 variant="caption">
                            {formOne.errors.birth_date}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Gender{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={genderOption}
                            onChange={(e) => {
                              formOne.setFieldValue("gender", e.value);
                            }}
                            value={{ label: formOne.values.gender }}
                            id="gender"
                            name="gender"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.gender && formOne.errors.gender ? (
                          <STypography5 variant="caption">
                            {formOne.errors.gender}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Marital Status{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>
                        <Select
                          isDisabled={nextStep}
                          placeholder="Select"
                          options={maritalstatusList}
                          onChange={(e) => {
                            formOne.setFieldValue("marital_status", e.value);
                          }}
                          value={{ label: formOne.values.marital_status }}
                          id="marital_status"
                          name="marital_status"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              color: "rgba(0, 0, 0, 0.3)",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              fontSize: "15px",
                              textAlign: "left",
                            }),
                          }}
                        />
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Address{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="org_address"
                          name="org_address"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.org_address}
                        />

                        {formOne.touched.org_address &&
                        formOne.errors.org_address ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_address}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          State{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={State}
                            onChange={(e) => {
                              formOne.setFieldValue("org_state", e.value);
                              setSelectedState(e.value);
                            }}
                            value={{ label: formOne.values.org_state }}
                            id="org_state"
                            name="org_state"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.org_state &&
                        formOne.errors.org_state ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_state}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          District{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={District}
                            onChange={(e) => {
                              formOne.setFieldValue("org_district", e.value);
                            }}
                            value={{ label: formOne.values.org_district }}
                            id="org_district"
                            name="org_district"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.org_district &&
                        formOne.errors.org_district ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_district}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Taluka{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="taluka"
                          name="taluka"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.taluka}
                        />

                        {formOne.touched.taluka && formOne.errors.taluka ? (
                          <STypography5 variant="caption">
                            {formOne.errors.taluka}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Pin Code{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}></sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="tel"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "6",
                            minLength: "6",
                          }}
                          fullWidth
                          id="org_pincode"
                          name="org_pincode"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.org_pincode}
                        />

                        {formOne.touched.org_pincode &&
                        formOne.errors.org_pincode ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_pincode}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                  </Grid>

                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1
                      onClick={
                        nextStep
                          ? () => {
                              // setShow(true);
                              setShow(1);
                            }
                          : () => {
                              //setSubmitformOne(true);
                              //setShow(1);
                              //handleNext();
                              formOne.handleSubmit();
                            }
                      }

                      //onClick={() => { setShow(true); handleNext() }}
                    >
                      <STypography6 variant="body1">
                        Proceed to Next Step
                      </STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        formOne.handleReset();
                        formMinusOne.handleReset();
                        setSubmitformOne(false);
                        setNextStep(false);
                        handleReset();
                        setShow(-1);
                        //navigate(-1);
                      }}
                    >
                      <STypography6 variant="body1">Back</STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            )}

            {show === -1 && (
              <>
                <SBox2>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <STypography2 variant="body1">Registration</STypography2>

                    {matchsm ? (
                      <img
                        src={img1}
                        style={{ height: "53.13px", margin: "8px" }}
                        alt=""
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "190px",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <STypography4 variant="body1">
                        Mobile Number{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>
                    </Box>

                    <TextField
                      //disabled={nextStep}
                      type="tel"
                      fullWidth
                      id="mobnumber"
                      name="mobnumber"
                      size="small"
                      inputProps={{
                        pattern:
                          "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                        maxLength: "10",
                        minLength: "10",
                      }}
                      onChange={formMinusOne.handleChange}
                      value={formMinusOne.values.mobnumber}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "139px", background: "red" }} />

                    {formMinusOne.touched.mobnumber &&
                    formMinusOne.errors.mobnumber ? (
                      <STypography5 variant="caption">
                        {formMinusOne.errors.mobnumber}
                      </STypography5>
                    ) : null}
                  </Box>

                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1 onClick={formMinusOne.handleSubmit}>
                      <STypography6 variant="body1">Submit</STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        navigate(-1);
                        //setShow(-1);
                        setSubmitform(false);
                        formTwo.handleReset();
                        formOne.handleReset();
                        setNextStep(false);
                        handleReset();
                        setResumeFile("");
                        setResumeshow(false);
                        setResumeshow1(0);
                        setLanguagesall([]);
                        setPreffere([]);
                        setSkillingall([]);
                        formMinusOne.handleReset();
                      }}
                    >
                      <STypography6 variant="body1">Cancel</STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            )}
          </div>
        </Box>

        <Dialog
          open={open}
          //onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={
              matchsm
                ? { textAlign: "center", width: "350px" }
                : { textAlign: "center", maxWidth: "350px" }
            }
          >
            <Box
              sx={
                matchsm
                  ? {
                      width: "300px",
                      m: 2,
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                      border: "3px dashed #A7A7A7",
                      minHeight: "300px",
                    }
                  : {
                      maxWidth: "500px",
                      m: 2,
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                      border: "3px dashed #A7A7A7",
                      minHeight: "300px",
                    }
              }
            >
              <img src={img4} height={"70px"} alt="" />
              <STypography11 variant="body2">
                Form submited successfully
              </STypography11>
              <hr />

              <STypography4 variant="body1">
                Thank you for registering with us!
              </STypography4>

              <SButton3
                onClick={() => {
                  formTwo.handleReset();
                  setShow(2);
                  handleNext();
                  handleClose();
                  //window.location.reload();
                  setObj({
                    org_address: "",
                    org_state: "",
                    org_district: "",
                    taluka: "",
                    org_pincode: "",
                    mobnum: "",
                    email: "",
                    firstnm: "",
                    lastnm: "",
                    marital_status: "",
                    gender: "",
                    birth_date: "",
                  });
                }}
              >
                <STypography6 variant="body1">Select Course(s)</STypography6>
              </SButton3>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={open1}
          //onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={
              matchsm
                ? { textAlign: "center", width: "350px" }
                : { textAlign: "center", maxWidth: "350px" }
            }
          >
            <Box
              sx={
                matchsm
                  ? {
                      width: "300px",
                      m: 2,
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                      border: "3px dashed #A7A7A7",
                      minHeight: "300px",
                    }
                  : {
                      maxWidth: "300px",
                      m: 2,
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                      border: "3px dashed #A7A7A7",
                      minHeight: "300px",
                    }
              }
            >
              <img src={img4} height={"70px"} alt="" />
              <STypography11 variant="body2">
                Details saved successfully
              </STypography11>
              <hr />

              <STypography4 variant="body1">
                “You are just one step away to start your journey”
              </STypography4>

              <STypography9 variant="body1">
                Please do not close the window or go back
              </STypography9>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
      {/* Modal for Job description */}

      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "350px" }
              : { textAlign: "center", maxWidth: "350px" }
          }
        >
          <Box
            sx={
              matchsm
                ? {
                    width: "300px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "200px",
                  }
                : {
                    maxWidth: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "200px",
                  }
            }
          >
            <img src={img5} height={"70px"} alt="" />
            <hr />

            <STypography4 variant="body1">
              You can select upto 3 courses
            </STypography4>

            <SButton3
              onClick={() => {
                handleClose4();
              }}
            >
              <STypography6 variant="body1">Close</STypography6>
            </SButton3>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={jdModal}
        onClose={() => (setJDModal(false), setJd(""))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "350px" }
              : { textAlign: "center", maxWidth: "350px" }
          }
        >
          <Box sx={{ m: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "18px",
                }}
              >
                Course Description
              </Typography>

              {/* <button onClick={() => setJDModal(false)}>
                <span> */}
              <CloseIcon
                fontSize="small"
                onClick={() => setJDModal(false)}
                style={{ cursor: "pointer" }}
              />
              {/* </span>
              </button> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              {/* <Typography
                fontWeight="medium"
                fontSize="14px"
                lineHeight="19px"
                color="#3B5092"
                marginBottom="30px"
              >
                Job Description
              </Typography> */}
              <Typography
                fontWeight="small"
                fontSize="14px"
                lineHeight="19px"
                color="#000"
                dangerouslySetInnerHTML={createMarkup(jd.discription)}
              ></Typography>
            </Box>
            <br />
            <Button
              sx={{
                background: "#FF7A00",
                border: "1px solid #FF7A00",
                boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
                color: "#FFFFFF",
                borderRadius: "5px",
                textTransform: "none",
                padding: "16px",
                paddingTop: "12px",
                paddingBottom: "12px",
                margin: "8px",
                "&:hover": {
                  background: "#FF7A00",
                  border: "1px solid #FF7A00",
                  cursor:
                    jd?.brochure === "" ||
                    jd.brochure === null ||
                    jd.brochure === undefined
                      ? "no-drop"
                      : "pointer",
                },
              }}
              onClick={() => (
                jd?.brochure === "" ||
                jd.brochure === null ||
                jd.brochure === undefined
                  ? console.log("text")
                  : downloadfile(),
                setJDModal(false),
                setJd("")
              )}
            >
              <STypography6 variant="body1">Download Brochure</STypography6>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Modal for Job description */}
    </>
  );
};
Skilling.propTypes = {
  bannerState: PropTypes.string,
};
export default Skilling;
